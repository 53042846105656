import React from "react"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Sub_Navigation from "../components/common/navigation/sub_navigation"
import Policy from "../components/sections/policy"
import Footer from "../components/sections/footer"


const PrivacyPage = () => (
    <Layout>
    <SEO title="Privacy" />
    <Sub_Navigation />
    <Policy />
    <Footer />
  </Layout>
)


export default PrivacyPage