import React from "react"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="start">
    <StyledContainer>
      <SectionTitle>Arco Innovation's Privacy Policy</SectionTitle>
          <JumpTo> 
              <strong>Jump to:</strong>
              <div style={mystyle}></div>
              <div>
                <span style={mystyle}><AnchorLink  offset='100' href="#general">General Policy</AnchorLink></span>
                <span style={mystyle}><AnchorLink  offset='100' href="#internet">Internet Policy</AnchorLink></span>
                <span style={mystyle}><AnchorLink  offset='100' href="#anti-spam">Anti-Spam Policy</AnchorLink></span>
                </div>
            </JumpTo>

      <FeaturesGrid>
        <FeatureItem id="general">
          <FeatureTitle>Arco Innovation's General Policy on Privacy</FeatureTitle>
          <FeatureText>
          Arco Innovation respects your right to privacy. Protecting privacy and confidentiality of Arco's customers and employee's personal information is an important aspect of our operations. 
          It is the responsibility of all Arco's employees to follow Arco Innovation's Privacy Policy when collecting and handling the personal informaiton of Arco's customers and employees.
          <br></br>
          <br></br>
          It is Arco's policy that Arco Innovation obtain an individual's consent when an Aro's employee or website collects, uses or discloses personal informaiton.
          Arco Innovation will aslo inform its customers and employees of the purpose for the collection, use and disclosure of personal inforamation.
          Arco Innovation takes care to safeguard all personal information in its possession against loss, theft and unauthorized use or disclosure. Arco Innovation uses reasonable efforts to ensure that such information is accurate and up to date. 
          <br></br>
          <br></br>
          If you have any questions about this Privacy Policy, please email us at <a href="mailto:support@arcoinnovation.com">support@arcoinnovation.com</a> 
          </FeatureText>
        </FeatureItem>
        <FeatureItem id="internet">
        <FeatureTitle>Arco Innovation's Internet Policy</FeatureTitle>
        <FeatureText>
        Arco Innovation will only collect personally identifiable information (such as name, address, telephone number, or email address) that you voluntarily provide through our website or email correspondence. We will collect general information (such as the type of browser you use, the files you request, and the domain name and country from which you request information) to improve our website and better meet your needs.
        <br></br>
        <br></br>
        <strong>Device and browser information.</strong> Arco Innovation may collect technical information about your device, such as device type, browser type, IP address, operating system, and device identifier. Arco Innovation collects this information automatically from your device and web browser through cookies and similar technologies.
        <br></br> 
        <br></br>
        <strong>Information about how you interact with us.</strong> Arco Innovation  may collect technical data about your usage of Arco Innovation Internet Sites and Apps and how you interact with our digital advertisements and promotions, such as content viewed or downloaded, features used, links clicked, Arco Innovation promotional emails opened, and dates and times of interactions. Arco Innovation collects this information using cookies and similar technologies.
        <br></br>
        <br></br>
        <strong>Location information.</strong> Arco Innovation  may collect location information, including precise real-time location information from your device and imprecise location information derived from, for example, your IP address or postal code. Arco Innovation's Apps will NOT access precise real-time location information from your device unless you grant permission to do so.


          </FeatureText>
        </FeatureItem>
        <FeatureItem id="anti-spam">
          <FeatureTitle>Arco Innovation's Anti-Spam Policy</FeatureTitle>
          <FeatureText>
          Canada's Anti-Spam Legislation (CASL) applies to specific types of commercial activities performed through electronic means.
        <br></br>
        <br></br>
        <strong>CASL prohibits:</strong> 
        <br></br>
        <br></br>
        Promoting products and/or services using misleading or false representations online
        collecting and using personal information without consent of the individual
        the use of and installation of spyware, malware and other technology invasions
        <br></br>
        <br></br>
        <strong>CASL regulates and requires consent for the following:</strong>
        <br></br>
        <br></br>
        &#8226; sending commercial electronic messages (“CEMs”)
        <br></br>
        &#8226;  altering transmission data in electronic messages
        <br></br>
        &#8226; installing computer programs on an individual’s computer device.  
        <br></br>
        <br></br>
        Arco Innovation is committed to complying with Canada’s Anti-Spam Legislation (CASL), including the requirement of obtaining consent for sending Commercial Electronic Messages (CEMs) in any electronic form, including email, text messages, instant messages and social media.
        Our Anti-Spam Policy tolerates only CASL-compliant email.
        <br></br>
        <br></br>
        We regularly communicate with employees of institutions in with which we do business with in respect to the matters of the institution and the delivery of educational materials or services, including but not limited to new resources or services of interest, information requests, order statuses and shipping confirmations, by electronic means. We also communicate with third parties relevant to the nature of our business.
        All emails sent by Arco Innovation's employees will be compliant with CASL and will include the option to ‘unsubscribe.’
        <br></br>
        <br></br>
        You can ‘unsubscribe’ from our Commercial Electronic Messages at any time by clicking the "Update your preferences" link in your welcome e-mail we sent you when you provided us with your e-mail address or by e-mailing <a href="support@arconnovation.com">support@arcoinnovation.com</a> with ‘Unsubscribe’ in the subject line.
        If you choose to ‘unsubscribe,’ Arco Innovation will no longer be able to communicate with you by electronic means. We will complete this change no later than 10 business days after receiving your request.
        <br></br>
        <br></br>
        For our <strong>terms and conditions of sale</strong> please visit the following <a href="/terms">link</a> also found in the footer of this website under Policies.
          </FeatureText>
        </FeatureItem>
             
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const mystyle = {
    width: "150px",
    height: "10px",
    display: "inline-block",
}

const mystyleBox = {
    padding: '10px',
}

const StyledContainer = styled(Container)`
margin: 80px auto;
}
`

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  text-align: center;
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: justify;
`
const JumpTo = styled.div`
  padding: 10px;
  display: flex;
  background-color: rgb(211,211,211);
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
